import React from "react";
import { hydrate, render } from "react-dom";
// import ReactDOM from "react-dom/client";
import MenuProvider from "react-flexible-sliding-menu";

import App from "./App";
import Menu from "./components/general/menu";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// const root = ReactDOM.createRoot(document.getElementById("root"));
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <MenuProvider MenuComponent={Menu} direction="right" width="100vw">
        <App />
      </MenuProvider>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <MenuProvider MenuComponent={Menu} direction="right" width="100vw">
        <App />
      </MenuProvider>
    </React.StrictMode>,
    rootElement
  );
}

// root.render(
//   <React.StrictMode>
//     <MenuProvider MenuComponent={Menu} direction="right" width="100vw">
//       <App />
//     </MenuProvider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
