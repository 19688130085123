import { Rating, RoundedStar } from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BASE_URL } from "../utils/axios/axios";
import useAxiosAuth from "../utils/axios/useAxiosAuth";
import NotFound from "./notFound";

const Review = () => {
  const { reviewId } = useParams();

  const { data: reviewDetails } = useAxiosAuth(`testimonial/${reviewId}`);

  console.log(reviewDetails);

  const [empStars, setEmpStars] = useState(false);

  // form USFs
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [location, setLocation] = useState("");
  const [stars, setStars] = useState(0);
  const [email, setEmail] = useState("");
  const [testimonial, setTestimonial] = useState("");

  const submitReview = (event) => {
    event.preventDefault();
    if (stars >= 1) {
      let data = new FormData();
      // Package Details
      data.append("Name", `${firstName} ${lastName}`);
      data.append("EmailAddress", email);
      data.append("Location", location);
      data.append("Rating", stars);
      data.append("Description", testimonial);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${BASE_URL}/api/testimonial/${reviewId}/review`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setFirstName("");
          setLastName("");
          setLocation("");
          setStars(0);
          setEmail("");
          setTestimonial("");
          setEmpStars(false);

          toast.success("Review recorded succesfully! Thank you!");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Review has failed!");
        });
    } else {
      setEmpStars(true);
    }
  };
  // for star
  const customStyles = {
    itemShapes: RoundedStar,
    activeFillColor: "#f59e0b",
    inactiveFillColor: "#ffedd5",
  };

  return reviewDetails !== undefined && reviewDetails.reviewRequested ? (
    <div className="xl:w-[90vw] px-5 mx-auto pt-12 pb-5 py-10">
      <Helmet>
        <title>Review</title>
        {/* <meta name="description" content="Fill here" /> */}
        <meta
          name="keywords"
          content="Druk Air Holidays reviews, Bhutan trip feedback, travel experiences Bhutan, customer testimonials Druk Air"
        />
      </Helmet>
      <h1 className="text-5xl font-bold text-[#1D306D] pb-2">Review</h1>
      <p className="text-[#8A94B2] pb-5">Leave a review of our service!</p>
      <form action="" className="text-[#4B4F52] w-full" onSubmit={submitReview}>
        <div className="shadow-xl border rounded-lg p-5 pt-12 mb-7">
          <div className="md:grid md:grid-cols-2 gap-8">
            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                required
              />
              <label
                htmlFor="firstName"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                First Name
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                required
              />
              <label
                htmlFor="lastName"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Last Name
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                type="email"
                name="emailAddress"
                id="emailAddress"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
              <label
                htmlFor="emailAddress"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Email Address
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                type="text"
                name="location"
                id="location"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                required
              />
              <label
                htmlFor="location"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Location
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              {/* <input
                type="number"
                name="contactNo"
                id="contactNo"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                min={1}
                max={5}
                placeholder=""
                value={stars}
                onChange={(e) => {
                  setStars(e.target.value);
                }}
                required
              /> */}

              <Rating
                className="block py-2.5 px-4 w-full focus:outline-none focus:ring-0 peer"
                value={stars}
                onChange={setStars}
                itemStyles={customStyles}
                style={{ maxWidth: 180 }}
                isRequired
              />
              <label
                htmlFor="contactNo"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Rate us
              </label>
              {empStars && (
                <span className="font-bold text-sm text-red-500 ml-5">
                  Make sure to rate us!
                </span>
              )}
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md col-span-2">
              <textarea
                type="textarea"
                name="specialRequest"
                id="specialRequest"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                rows={6}
                value={testimonial}
                onChange={(e) => {
                  setTestimonial(e.target.value);
                }}
                required
              />
              <label
                htmlFor="specialRequest"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Review
              </label>
            </div>
          </div>

          <div className="w-[162px] mx-auto">
            <input
              type="submit"
              value="Book Now"
              className="w-full mx-auto bg-secondary text-white rounded-full py-4"
            />
          </div>
        </div>
      </form>
    </div>
  ) : (
    <NotFound />
  );
};

export default Review;
