import axios from "axios";
import React, { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { FaRegStar, FaStar } from "react-icons/fa";
import { IoIosTimer } from "react-icons/io";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { MdOutlineGroups } from "react-icons/md";
import { MdOutlineFlight } from "react-icons/md";
import { toast } from "react-toastify";

import { BASE_URL } from "../../utils/axios/axios";

const PackageLayout = ({ packageInformation, group, children }) => {
  // const axios = useAxiosAuth();
  const [email, setEmail] = useState("");

  let cards = [
    {
      text: `${packageInformation.tripDuration} Days`,
      icon: <IoIosTimer />,
      iconText: "Trip Duration",
    },
    {
      text: packageInformation.coveredArea,
      icon: <BiCurrentLocation />,
      iconText: "Area",
    },
    {
      text: `Minimum of ${packageInformation.groupSize}`,
      icon: <MdOutlineGroups />,
      iconText: "Group Size",
    },
    {
      text: packageInformation.flight ? "Available" : "Not Available",
      icon: <MdOutlineFlight />,
      iconText: "Flight Options",
    },
  ];

  const getDate = (date) => {
    var initDate = new Date(date);
    var year = initDate.getFullYear();
    var day = initDate.getDate();
    var finalDate =
      day +
      " " +
      initDate.toLocaleString("default", { month: "short" }) +
      ", " +
      year;

    // console.log(finalDate.parse("Jan 1, 1970"));
    return finalDate;
  };

  const getStars = (item) => {
    const starsInit = [];
    for (let i = 0; i < item; i++) {
      starsInit.push(<FaStar className="text-[#F2994A] text-lg" />);
    }
    for (let i = 0; i < 5 - item; i++) {
      starsInit.push(<FaRegStar className="text-[#F2994A] text-lg" />);
    }
    return starsInit;
  };

  return (
    <div className="bg-[#F7F7F7]">
      <div className="w-[90vw] mx-auto pt-14 ">
        <div className="md:flex gap-6">
          <div>
            <div className="md:flex justify-between items-center leading-8">
              <div>
                <h1 className="md:text-2xl text-lg mb-2">
                  {packageInformation.title}
                </h1>

                <div className="flex flex-col sm:flex-row justify-between md:justify-normal gap-3">
                  <div className="flex items-center gap-0.5 mb-2">
                    {getStars(packageInformation.rating)}

                    {/* <p className="text-gray-400 md:text text-sm ">
                      &nbsp; 4.5 (1200 Reviews)
                    </p> */}
                  </div>

                  {group ? (
                    <>
                      <div className="flex flex-row items-center gap-2 mb-2 text-secondary">
                        <AiOutlineUser className="md:text-lg text-3xl" />
                        <div>{group.totalPax}</div>
                        PAX
                      </div>
                      <div className="flex flex-row items-center gap-2 mb-2 text-secondary">
                        <IoCalendarNumberOutline className="md:text-lg text-3xl" />
                        <div>{getDate(group.departureDate)}</div>
                        to
                        <div>{getDate(group.returnDate)}</div>
                      </div>
                    </>
                  ) : (
                    <p className="flex items-center md:gap-0 gap-3 mb-2 text-secondary">
                      <IoCalendarNumberOutline className="md:text-lg text-3xl" />
                      &nbsp;
                      {packageInformation.tripDays &&
                        ("00" + packageInformation.tripDays.toString()).slice(
                          -"00".length
                        )}{" "}
                      Days /{" "}
                      {packageInformation.tripNights &&
                        ("00" + packageInformation.tripNights.toString()).slice(
                          -"00".length
                        )}{" "}
                      {packageInformation.tripNights === 1 ? "Night" : "Nights"}
                      &ensp;
                    </p>
                  )}
                </div>
                <p className="flex items-center md:pb-8">
                  <CiLocationOn className="text-lg text-secondary" /> &nbsp;
                  {packageInformation.coveredArea}
                </p>
              </div>
            </div>
            {children}
          </div>
          <div className="flex flex-col space-y-2">
            <div className="w-full p-4 bg-[#ffffff] rounded-lg border flex flex-col ">
              <div>
                <span className="text-3xl">
                  {packageInformation.currency}&nbsp;
                  {packageInformation.price &&
                    packageInformation.price.toLocaleString()}
                </span>
                / Adult
              </div>

              <span className="mt-2 mb-4 text-[12px] text-[#8A94B2]">
                Excluding Applicable Taxes
              </span>

              <a
                href={`/package/${packageInformation.slug}/request`}
                className="bg-secondary text-sm text-center text-white  p-2 px-5 rounded-full h-fit "
              >
                Book Package
              </a>
            </div>

            <div className="w-full px-4 py-8 bg-[#ffffff] rounded-lg border flex flex-col ">
              <div className="relative z-0 w-full group">
                <input
                  type="email"
                  name="emailItinerary"
                  id="emailItinerary"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                  placeholder=""
                />
                <label
                  htmlFor="emailAddress"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
                >
                  Email Itinerary
                </label>
              </div>
              <button
                className="bg-black text-sm text-white p-2 px-5 mt-4 rounded-full h-fit"
                onClick={(e) => {
                  e.preventDefault();

                  // Show loading toast
                  const toastId = toast.loading(
                    "Sending package information..."
                  );

                  axios
                    .get(
                      `${BASE_URL}/api/package/${packageInformation.id}/send-details/`,
                      {
                        params: { to: email },
                      }
                    )
                    .then((response) => {
                      setEmail("");

                      // Update loading toast to success and clear the toast ID
                      toast.update(toastId, {
                        render: "Package information sent successfully!",
                        type: "success",
                        isLoading: false,
                        autoClose: 3000,
                      });
                    })
                    .catch((error) => {
                      // Update loading toast to error and clear the toast ID
                      toast.update(toastId, {
                        render: "Failed to send package information.",
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                      });
                    });
                }}
              >
                Send
              </button>
            </div>
            <a href="/faq">
              <img
                className="rounded-xl w-full"
                src={require("../../assets/images/faq.png")}
                alt="FAQ"
              />
            </a>
            <a href="/tailormadetrip">
              <img
                className="rounded-xl w-full"
                src={require("../../assets/images/tailor.png")}
                alt="tailorMadeTrip"
              />
            </a>
          </div>
        </div>
        <div className="pt-12 md:grid md:grid-cols-2 lg:flex gap-6 pb-16 md:space-y-0 space-y-6">
          <div className="flex items-center md:col-span-2 lg:w-[20vw] w-full h-[20vh] p-8 lg:py-6 lg:px-4 bg-[#1D306D] border  rounded-2xl ">
            <h5 className="mb-2 text-white ">
              Trip Facts That Would Make Your Visit Even More Enjoyable
            </h5>
          </div>

          {cards.map((item, index) => (
            <div
              key={index}
              className="flex lg:w-[20vw] w-full md:h-[20vh] h-[15vh] p-8 lg:py-6 lg:px-4  border bg-[#FFFFFF]  rounded-2xl  justify-center items-center text-center"
            >
              <div className="md:space-y-12 space-y-8">
                <h5 className="text-lg h-[2vh]">{item.text}</h5>
                <p className="flex items-center justify-center text-secondary">
                  {item.icon} &nbsp; {item.iconText}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PackageLayout;
