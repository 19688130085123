import React from "react";

import bgImg from "../../assets/images/serving.png";
import servingSvg1 from "../../assets/images/servingSVG1.svg";
import servingSvg2 from "../../assets/images/servingSvg2.svg";
import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

const Serving = () => {
  const { data: carousel } = useAxiosAuth(`carousel`);
  const images = carousel.filter((image) =>
    image.identifier.includes("homeGrid")
  );

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${bgImg})`,
        }}
        className="xl:h-[95vh] h-[65vh] relative bg-cover"
      >
        <img
          className="absolute -top-[1px] w-screen h-fit"
          src={servingSvg1}
          alt=""
        />
        <img
          className="absolute -bottom-[1px] object-top w-screen object-cover xl:h-[55vh] md:h-[30vh] h-[20vh] xl:scale-x-100 -scale-x-100"
          src={servingSvg2}
          alt=""
        />
      </div>
      <div className="bg-[#F7F7F7] relative xl:h-10 px-5 md:pb-20 pb-8">
        <h2 className="xl:absolute md:text-8xl text-6xl text-secondary md:top-[-28vh] top-[-20vh] 2xl:left-[30vw] md:left-[30vw] left-[20vw]">
          20+ <br className="xl:block hidden" /> Years
        </h2>
        <div className="xl:float-right xl:w-[50vw] relative mx-auto xl:text-right">
          <div className="2xl:pr-10 xl:top-[-45vh] md:pr-10 py-5 xl:absolute bottom-16">
            <h2 className="md:text-5xl text-4xl mb-4 text-[#1D306D]">
              Serving With Excellence
            </h2>
            <p className="paragraph">
              Drukair Holidays (DH) is the holiday wing of Drukair - Royal
              Bhutan Airlines , is a one-stop enterprise offering luxury
              vacations, private tours and group tour adventures in Bhutan. DH
              is well-regarded for its professionalism, comprehensive service
              offerings, and commitment to providing authentic and sustainable
              travel experiences in Bhutan.
            </p>
            <a
              href="./contact"
              className="bg-secondary text-white text-sm p-4 rounded-full"
            >
              Learn More !
            </a>
          </div>
        </div>
      </div>

      {images.length > 0 && (
        <div className="xl:w-[90vw] px-5 mx-auto mb-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-6 ">
          {images.slice(0, 4).map((image, index) => {
            return (
              <img
                src={`${BASE_URL}/${image.image.filePath}`}
                alt={image.image.altinfo}
                className="rounded-3xl w-full xl:h-[50vh] lg:h-[40vh] sm:h-[35vh] h-[20vh] object-cover"
                key={index}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default Serving;
