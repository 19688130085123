import React from "react";
import { BsDiamondFill } from "react-icons/bs";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

const Choose = () => {
  const { data: carousel } = useAxiosAuth(`carousel`);
  const filteredCarousel = carousel
    .filter((image) => image.identifier.includes("chooseUs"))
    .slice(0, 3);

  let data = [
    {
      heading: "Convenience and Reliability",
      list: [
        "Being part of the national airline, they offer reliable services and the convenience of managing both your flights and tours through a single provider.",
      ],
    },
    {
      heading: "Safety and Security",
      list: [
        "They prioritize the safety and security of their travelers, ensuring all arrangements are made with the utmost care and attention to detail.",
      ],
    },
    {
      heading: "Comprehensive Service",
      list: [
        "From visa arrangements to accommodation, transportation, and guided tours, Drukair Holidays provides a comprehensive range of services to ensure a hassle-free trip.",
      ],
    },
    {
      heading: "Authentic Experiences",
      list: [
        "Drukair Holidays focuses on providing genuine and immersive experiences, allowing travelers to connect with the local culture and traditions authentically.",
      ],
    },
    {
      heading: "Expert Local Guides",
      list: [
        "Their knowledgeable and experienced local guides enhance the travel experience with in-depth insights into Bhutan’s culture, history, and natural wonders.",
      ],
    },
    {
      heading: "Commitment to Sustainability",
      list: [
        "They adhere to sustainable tourism practices that respect the environment and benefit local communities.",
      ],
    },
  ];

  const settings = {
    customPaging: function (i) {
      return (
        <div className="pt-5 w-[26vw] sm:w-[14vw] md:w-[10vw] h-[17vh] md:h-[18vh]">
          <img
            className="rounded-3xl object-cover w-full h-full"
            key={i}
            src={`${BASE_URL}/${filteredCarousel[i].image.filePath}`}
            alt={filteredCarousel[i].image.altInfo}
          />
        </div>
      );
    },
    dots: true,
    arrows: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="min-h-[70vh] mx-auto grid grid-cols-1 sm:grid-cols-2 gap-4 px-4 sm:px-8">
      <div className="servicesCarousel h-[50vh] md:max-w-[40vw] w-full mx-auto mt-10 sm:mt-0 mb-48 sm:mb-0">
        {filteredCarousel.length > 0 && (
          <Slider {...settings}>
            {filteredCarousel.map((image, index) => (
              <img
                key={index}
                className="w-full h-[50vh] lg:h-[60vh] 2xl:h-[50vh] object-cover rounded-3xl"
                src={`${BASE_URL}/${image.image.filePath}`}
                alt={image.image.altInfo}
              />
            ))}
          </Slider>
        )}
      </div>

      <div className="px-5 mx-auto  ">
        <h2 className="md:text-5xl text-4xl text-[#1D306D] pb-4 ">
          Why Choose Us
        </h2>
        <p className="text-[#8A94B2] pb-6 ">
          Planning a trip to Bhutan? Look no further than Drukair Holidays! We
          are your one-stop shop for an unforgettable Bhutanese experience.
        </p>
        <div className="pb-5 space-y-6">
          {data.map((item, index) => (
            <div key={index}>
              <h2 className="pb-2 inline-flex items-center md:text-lg font-semibold gap-2 text-black">
                <BsDiamondFill className="text-[#1D306D]" />
                {item.heading}
              </h2>
              <div className="text-gray-500 list-disc list-outside pl-6 leading-7">
                {item.list.map((list, index) => (
                  <p key={index}>{list}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Choose;
